@use '../base';
.link-and-bottom {
    position:absolute;
    background-color: var(--primary-color-main);
    height: 3px;
    z-index: 0;
    span {
        transform: rotate(-90deg); //line will get transformed 90deg. transform the span back to horizontal
        display: block;
        background: #fff;
        position:relative;
        // left: 50%;
        left: 8px;
        top:-12px;
        border: 2px solid var(--primary-color-main) ;
        border-radius: 15px;
        height: 30px;
        line-height: 20px;
        padding: 2px 10px;
        width: fit-content;
    }
    &.optionalEnabled{
        height: 3px;
        position: absolute;
        // rgba(255,255,255,0) is white transparent = completely transparent
        background: repeating-linear-gradient( 90deg, rgba(255,255,255,0), rgba(255,255,255,0) 8px, var(--primary-color-main) 8px, var(--primary-color-main) 16px);
        display: block;
        span{
            border: 2px dashed var(--primary-color-main)
        }
    }
    &.notExistsEnabled {
		// rgba(255,255,255,0) is white transparent = completely transparent
        background: repeating-linear-gradient( 90deg, rgba(255,255,255,0), rgba(255,255,255,0) 8px, #000 8px, #000 16px);
		span {
			border: 3px dashed #000;
			border-radius: 15px;
		}
	}
    &.serviceEnabled {
        height: 3px;
        position: absolute;
        // rgba(255,255,255,0) is white transparent = completely transparent
        background: repeating-linear-gradient( 90deg, rgba(255,255,255,0), rgba(255,255,255,0) 8px, var(--ternary-color-main) 8px, var(--ternary-color-main) 16px);
        display: block;
        span{
            border: 2px dashed var(--ternary-color-main)
        }
	}
}