.EndClassWidgetGroup {
    width: auto;
    padding: 0 15px 0 0;
    margin-left: -43px;
    display: flex;
    span.unselect {
        top: -10px;
    }

    /* Unselect to graphique  */
	div.edit {
		/*position: absolute;*/
		display: block;
		position: absolute;
		top: -10px;
		right: 32px;
		width: 22px;
		height: 22px !important;
		padding: 0 0;
		text-align: center;
		cursor: pointer;
		color: var(--primary-color-important);
		line-height: 22px;
		font-size: 18px;
		box-sizing: border-box;
		border-radius: 11px;
		z-index: 2;
	}
}