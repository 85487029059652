

.submitSection {
    height: 41px;
    background: var(--secondary-color-main) ;
    position: relative;
    margin: 0 auto;
    width: 100%;
    a {
        position: absolute;
        display: flex;
        top: 14px;
        width: fit-content;
        right:30px;
        padding: 10px;
        padding-left: 12px;
        border-radius: 5px;
        background: var(--secondary-color-important);
        box-shadow: 0px 2px 3px 0px #cfcfcf;
        cursor: pointer;
        z-index: 2;
        svg {
            display: block;
            max-width:  34px;
            height: 34px;
            path {
                fill:#FFFFFF;
                width: auto;
                height: auto;
            }
        }
        svg.playLoader {
            display: none;
            max-width:  44px;
            height: 44px;
        }

        &.submitDisable {
            background: var(--silver-color-light);
            cursor: default;
            svg {
                path {
                    fill:var(--silver-color-main);
                }
            }
            &.loadingEnabled {
                padding: 4px;
                svg.playIcon {
                    display: none;
                }
                svg.playLoader {
                    display: block;
                    path {
                        fill:var(--secondary-color-important);
                    }
                    
                }
            }
        }
    }
}


