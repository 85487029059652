
/**
 Note how the theme applies to Sparnatural as well as other technical classes with absolute positioning that
 also needs to benefits from the variables.
 **/
.Sparnatural,
.select2-container--default,
.tippy-box
{
/**
 * Color of the criteria arrows (orange)
 **/
--primary-color-main: rgb(250,136,3);

 /**
  * Color of the "unselect" buttons in criterias (dark orange)
  **/
--primary-color-important: rgb(182, 97, 0) ;
 
 /**
  * Color of the bottom : folded variable selector, and of a selected value in a criteria (light orange)
  **/
--primary-color-medium: rgb(255, 210, 157) ;
 
 /**
  * variable selector background & tooltip background (even lighter orange)
  **/
--primary-color-light: rgb(255, 231, 203) ;
 
 /** 
  * Color of the options arrow (optional / negation) (strong green)
  **/
--secondary-color-main: rgb(107,215,171) ;
 
 /** 
  * Color of the highlight of arrows
  **/
--secondary-color-important: rgb(2,184,117) ;
 
 /**
  * Color of the options arrow when not selected (light green)
  **/
--secondary-color-light: rgb(151,227,196) ;
 
 /**
  * Color of the service option enabled
  **/
--ternary-color-main: rgb(3, 201, 250) ;

/**
  * Color of the hover of autocomplete selection
  **/
--ternary-color-light: rgb(184, 211, 224);
 
 /**
  * Color of variable d&d grip, disabled query button, disabled options
  **/
--silver-color-main: rgb(167,167,167) ;
 
 /**
  *
  **/
--silver-color-light: rgb(230, 230, 230) ;
 
 /**
  * Default text color
  **/
--default-text-color: #666 ;

/**
 * Red, Green and Blue components for the background color.
 * An alpha is added to this color at each line (0.1, 0.2, 0.3, etc.)
 **/
--bg-red: 29 ;
--bg-green: 224 ;
--bg-blue: 153 ;

}