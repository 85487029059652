@use "../../../base";

.ObjectPropertyGroup {
    margin-right: -15px;
    padding-left: 10px;
    .nice-select{
        padding-left:1em;
    }
    .current {
        font-style: italic;
    }
    .temporary-label {
        line-height: base.$height-components-under - 2;
        font-size: 14px;
        height: 42px;
        padding-left: 20px;
        padding-right: 25px;
        display: inline-block;
        color: var(--default-text-color);
    }
}