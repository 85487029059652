@use '../base';

ul.componentsListe {
    padding: 0;
    width: base.$component-width;
    margin: 0 auto;

    /*
    Thomas 20231219 : As I don't understand these rules and they are too generic and mess up with awesomplete
    I comment them

    &,
    ul {
        list-style-type: none;
        padding: 0;
        background: transparent;
        clear: both;
        border: 0 solid transparent;
    }
    */
    //
    ul {
        li li.OnEdit > div > div > .ActionWhere.ShowOnEdit {
            display: none;
        }
    }
    

    li.groupe.optional-enabled {
        .CriteriaGroup {
            border: 3px dashed var(--primary-color-main);
        }

        ul.childsList {
            .ActionAnd {
                & > .trait-and-bottom {
                    border-left: 3px dashed var(--primary-color-main);
                }

                & > a {
                    border: 2px dashed var(--primary-color-main);
                }
            }

            & > li.hasAnd {
                & > span {
                    border-left: 3px dashed var(--primary-color-main);
                }

                & > span > span {
                    border: 2px dashed var(--primary-color-main);
                }
            }
        }
    }
} 