
span.unselect{
    cursor: pointer;
    position: absolute;
    top: 12px;
    right: 25px;
    border-radius: 100%;
    svg {
        fill: var(--primary-color-important);
        width: 18px;
        background-color: white;
        border-radius: 100%;
    }
}